import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Image from "next/legacy/image";
import {useTranslation} from 'next-i18next';
// image
import select1_light from '../../../public/img/step1-build-digital-wallet-light.png'
import select1_dark from '../../../public/img/step1-build-digital-wallet-dark.png'
import select2_light from '../../../public/img/step2-develop-collections-light.png'
import select2_dark from '../../../public/img/step2-develop-collections-dark.png'
import select3_light from '../../../public/img/step3-add-nfts-light.png'
import select3_dark from '../../../public/img/step3-add-nfts-dark.png'
import select4_light from '../../../public/img/step4-list-nft-for-sale-light.png'
import select4_dark from '../../../public/img/step4-list-nft-for-sale-dark.png'
// styles
import styles from "./CreateNFT.module.scss";


const CreateNFT = () => {
        const { t } = useTranslation("common");
        const imgList = [
            {
                lightImg: select1_light,
                darkImg: select1_dark,
                alt: "UPYO NFT Step 1 Build Up your Digital Wallet",
                text: t('common:nft_step_1'),
                desc: t('common:nft_step_desc_1')
            },
            {
                lightImg: select2_light,
                darkImg: select2_dark,
                alt: "UPYO NFT Step 2 Develop your Collections",
                text: t('common:nft_step_2'),
                desc: t('common:nft_step_desc_2')
            },
            {
                lightImg: select3_light,
                darkImg: select3_dark,
                alt: "UPYO NFT Step 3 Begin Adding your NFTs",
                text: t('common:nft_step_3'),
                desc: t('common:nft_step_desc_3')
            },
            {
                lightImg:
                select4_light,
                darkImg: select4_dark,
                alt: "UPYO NFT Step 4 List your NFT for Sale",
                text: t('common:nft_step_4'),
                desc: t('common:nft_step_desc_4')
            }
        ]
        return (
            <div className="section">
                <Container>
                    <div className={styles.createNft}>
                        <h2 className={`h3 ${styles.title}`}>{t('common:nft_steps_title')}</h2>
                        <div className={styles.cardWrap}>
                            {imgList.map((item, idx) => {
                                return (
                                    <Card className={styles.card} key={idx}>
                                        <div className={styles.cardImg}>
                                            <div className='light'>
                                                <Image src={item.lightImg} alt={item.alt} width={247} height={247} layout="responsive" />
                                            </div>
                                            <div className={`dark ${styles.darkImgs}`}>
                                                <Image src={item.darkImg} alt={item.alt} width={247} height={247} layout="responsive" />
                                            </div>
                                        </div>
                                        <Card.Body className={styles.cardBody}>
                                            <h3 className={styles.name}>{item.text}</h3>
                                            <Card.Text className={styles.text}>
                                                {item.desc}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                )
                            })}
                        </div>
                    </div>
                </Container>
            </div>

        )
}
export default CreateNFT;
